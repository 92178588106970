import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Dialog, Toolbar, AppBar, IconButton, TextField, DialogActions, Button } from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import { API } from "../../../modules/Api";
import * as Yup from "yup";
import "./index.scss";

class DialogOnline extends PureComponent {
  state = {
    recaptcha: "",
  }

  onlineSchema = () => {
    const { t: _t } = this.props;
    return Yup.object().shape({
      email: Yup.string()
        .email(_t("Invalid email"))
        .required(_t("Required")),
    });
  };

  onlineSend = ({ email, text }) => {
    API.post("/contact_us/new", { email, text, recaptcha: this.state.recaptcha }).then(
      response => {
        console.log(response);
        this.props.closeDialogOnline();
      },
      error => {
        console.log(error);
      },
    );
  };
  render() {
    const { t: _t } = this.props;
    return (
      <Dialog
        fullScreen
        open={this.props.isDialogOnlineOpen}
        onClose={this.props.closeDialogOnline}
        aria-labelledby="dialog-online"
        className="dialog-online"
      >
        <AppBar className="dialog-doctorRegister-appBar">
          <Toolbar className="dialog-doctorRegister-toolBar">
            <IconButton
              color="inherit"
              style={{ fontSize: "14px" }}
              onClick={this.props.closeDialogOnline}
              aria-label="close"
            >
              {_t("Close")}
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="dialog-online-content">
          <h2>{_t("Online")}</h2>
          <Formik
            initialValues={{
              email: "",
              text: "",
            }}
            validationSchema={this.onlineSchema}
            onSubmit={this.onlineSend}
            render={({ errors, values }) => (
              <Form className="dialog-online-form">
                <p>{_t("Write us a message and we will answer you shortly")}</p>
                <div className="dialog-online-wrap">
                  <Field
                    name="text"
                    render={({ field }) => (
                      <TextField
                        margin="dense"
                        multiline
                        rows="5"
                        rowsMax="40"
                        {...field}
                        className="dialog-online-textarea"
                      />
                    )}
                  />
                  <div className="dialog-online-icon" />
                </div>

                <Field
                  name="email"
                  render={({ field }) => (
                    <TextField
                      type="email"
                      label="Email Address"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      className="dialog-online-email"
                      error={!!errors.email}
                      helperText={errors.email}
                      {...field}
                    />
                  )}
                />
                <Button type="submit" className="dialog-online-send">
                  {_t("Send")}
                </Button>
              </Form>
            )}
          />
        </div>
      </Dialog>
    );
  }
}

DialogOnline.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(DialogOnline);
