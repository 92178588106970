import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { API } from "../../../modules/Api";
import ReCAPTCHA from "react-google-recaptcha";
import ask_loader1 from "../../../assets/images/ask_loader1.png";
import ImageCropper from "../../../components/ImageCropper";
import FileLoader from "../../../components/FileLoader";
import { Dialog, Toolbar, AppBar, IconButton, DialogTitle, Paper, TextField, Button } from "@material-ui/core";
import "./index.scss";

class DialogAssessment extends PureComponent {
  getStaticItems = _t => [
    {
      staticImageId: 1,
      title: _t("Frontal view of teeth"),
      imgPreview: ask_loader1,
      imgUpload: null,
    },
    // {
    //   staticImageId: 2,
    //   title: _t("Left view of teeth "),
    //   imgPreview: ask_loader2,
    //   imgUpload: null,
    // },
    // {
    //   staticImageId: 3,
    //   title: _t("Right view of teeth"),
    //   imgPreview: ask_loader3,
    //   imgUpload: null,
    // },
  ];
  state = {
    isChecked: false,
    disabl: true,
    staticImages: this.getStaticItems(this.props.t),
    // typeOfProblems: this.typeOfProblem(),
    dynamicImages: [],
    isSliderLoading: false,
    isMoreOpened: true,
    showDrop: false,
    isShowDialogClose: false,
    recaptcha: "",
    email: "",
    smileAssessmentStep: false,
  };

  SmileAssessmentSchema = () => {
    const { t: _t } = this.props;
    return Yup.object().shape({
      name: Yup.string().required(_t("Required")),
      city: Yup.string().required(_t("Required")),
      phone_number: Yup.string().required(_t("Required")),
      request_text: Yup.string().required(_t("Required")),
      recaptcha: Yup.string(),
      email: Yup.string()
        .email(_t("Invalid email"))
        .required(_t("Required")),
    });
  };
  sendSmile = ({ name, city, phone_number, email, request_text, image_1 }) => {
    this.setState({
      disabl: true,
    });
    const formData = new FormData();
    formData.set("name", name);
    formData.set("city", city);
    formData.set("phone_number", phone_number);
    formData.set("email", email);
    formData.set("request_text", request_text);
    // formData.set("crossbite", crossbite);
    // formData.set("rotated_teeth", rotated_teeth);
    // formData.set("deepbite", deepbite);
    // formData.set("openbite", openbite);
    // formData.set("inclined_teeth", inclined_teeth);
    // formData.set("spaces", spaces);
    // formData.set("crowding", crowding);
    // formData.set("crowding", crowding);
    // formData.set("underbite", underbite);
    formData.set("recaptcha", this.state.recaptcha);
    formData.append("image_1", image_1);
    // formData.append("image_2", image_2);
    // formData.append("image_3", image_3);
    // console.log("formData", formData, image_1, image_2, image_3);
    const options = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    API.post("/smileassessment/new", formData, options).then(
      response => {
        console.log("responseSmile", response);
        this.setState({ email: email, disabl: false, staticImages: this.getStaticItems(this.props.t) });
        this.props.smileChangePage();
      },
      error => {
        // const erroring = error.map(el => el.error.message);
        // if (erroring[0] === "The submitted data was not a file. Check the encoding type on the form.") {
        //   this.setState({
        //     errorImage: "Upload all image",
        //   });
        // } else {
        //   this.setState({
        //     errorImage: "",
        //   });
        // }
        // console.log(erroring[0]);
      },
    );
  };

  fileReader = async file => {
    return await new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onloadend = e => {
        resolve({ file: e.target.result, name: file.name });
      };
      reader.readAsDataURL(file);
    });
  };

  staticImageAction = (e, currentItem) => {
    if (currentItem.imgUpload) {
      e.preventDefault();
      this.setState({
        staticImages: [
          ...this.state.staticImages.map(el => {
            if (el.staticImageId === currentItem.staticImageId) {
              return { ...el, imgUpload: null };
            }
            return el;
          }),
        ],
      });
    }
  };

  staticImageAttach = async (e, id) => {
    const file = e.target.files[0];
    // Reset file input
    e.target.value = "";
    e.target.type = "";
    e.target.type = "file";
    if (e.target.files && file) {
      const fileItem = await this.fileReader(file);
      // console.log("fileItem", file);
      this.setState({
        staticImages: [
          ...this.state.staticImages.map(el => {
            if (el.staticImageId === id) {
              return { ...el, imgUpload: fileItem.file };
            }
            return el;
          }),
        ],
      });
    }
  };

  onChange = recaptchaToken => {
    // Here you will get the final recaptchaToken!!!
    this.setState({
      recaptcha: recaptchaToken,
      disabl: false,
    });
    console.log(recaptchaToken, "<= your recaptcha token");
  };

  handleChange = (id, e) => {
    if (id) {
      this.setState({
        typeOfProblems: [
          ...this.state.typeOfProblems.map(el => {
            if (el.id === id) {
              return { ...el, isChecked: !e };
            }
            return el;
          }),
        ],
      });
    }
    console.log(id, !e);
    // console.log(this.state.typeOfProblems);
  };

  // smileChangePage = email => {
  //   this.setState({
  //     smileAssessmentStep: !this.state.smileAssessmentStep,
  //     email: email,
  //   });
  // };

  render() {
    // const {smileAssessmentStep} = this.state;
    const { t: _t, fileUploadList, smileAssessmentStep } = this.props;
    console.log("this.state.recaptcha", this.state.recaptcha);
    return (
      <Dialog
        fullScreen
        open={this.props.isShow}
        onClose={this.props.isClose}
        aria-labelledby="dialog-askSmileAssessment"
        className="dialog-askSmileAssessment"
      >
        <AppBar className="dialog-askSmileAssessment-appBar">
          <Toolbar className="dialog-askSmileAssessment-toolBar">
            <IconButton color="inherit" onClick={this.props.isClose} aria-label="close">
              {_t("Close")}
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="container">
          <FileLoader fileLoadingList={fileUploadList} />
          <DialogTitle className="dialog-askSmileAssessment-title">{_t("Smile Assessment")}</DialogTitle>
          {smileAssessmentStep === false ? (
            <Formik
              initialValues={{
                name: "",
                city: "",
                phone_number: "",
                email: "",
                // problemType: this.state.typeOfProblems,
                // crossbite: false,
                // rotated_teeth: false,
                // deepbite: false,
                // openbite: false,
                // inclined_teeth: false,
                // spaces: false,
                // crowding: false,
                // underbite: false,
                request_text: "",
                image_1: null,
                // image_2: null,
                // image_3: null,
              }}
              validationSchema={this.SmileAssessmentSchema}
              onSubmit={values => {
                this.sendSmile(values);
              }}
              render={({ errors, setFieldValue, values }) => (
                // console.log("values",values["problemType"].map(el => el)),
                <Form>
                  <div className="dialog-askSmileAssessment-formWrap">
                    <Field
                      name="name"
                      render={({ field }) => (
                        <TextField
                          type="string"
                          label="Name"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          error={!!errors.name}
                          helperText={errors.name}
                          {...field}
                        />
                      )}
                    />
                    <Field
                      name="city"
                      render={({ field }) => (
                        <TextField
                          type="string"
                          label="City"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          error={!!errors.city}
                          helperText={errors.city}
                          {...field}
                        />
                      )}
                    />
                    <Field
                      name="phone_number"
                      render={({ field }) => (
                        <TextField
                          type="string"
                          label="Tel/Mob"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          error={!!errors.phone_number}
                          helperText={errors.phone_number}
                          {...field}
                        />
                      )}
                    />
                    <Field
                      name="email"
                      render={({ field }) => (
                        <TextField
                          type="email"
                          label="E-mail Address"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          error={!!errors.email}
                          helperText={errors.email}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="simplePaper dialog-askSmileAssessment-simplePaper">
                    <p className="dialog-askSmileAssessment-simplePaper-text">
                      {_t("Write what you don't like about your smile:")}
                    </p>
                    <div className="dialog-askSmileAssessment-simplePaper-textareaWrap">
                      <Field
                        name="request_text"
                        render={({ field }) => (
                          <TextField
                            margin="dense"
                            multiline
                            type="string"
                            error={!!errors.request_text}
                            helperText={errors.request_text}
                            rows="1"
                            rowsMax="40"
                            {...field}
                            className="dialog-askSmileAssessment-simplePaper-textarea"
                          />
                        )}
                      />
                      <div className="dialog-askSmileAssessment-simplePaper-textarea-icon"></div>
                    </div>
                    {/* <p className="dialog-askSmileAssessment-simplePaper-text">{_t("Choose type of problem:")}</p> */}
                    {/* <div className="dialog-askSmileAssessment-simplePaper-checkboxes">
                      {values["problemType"].map(item => (
                        <div className="dialog-askSmileAssessment-simplePaper-item" key={item.id}>
                          <img src={item.img} alt="" />
                          <Field
                            name={item.name}
                            render={({ field }) => {
                              return (
                                <div className="dialog-askSmileAssessment-simplePaper-checkbox">
                                  <FormControl
                                    required
                                    error={this.state.typeOfProblems.filter(el => el.isChecked === true).length < 1}
                                    component="fieldset"
                                    {...field}
                                  >
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={field.value}
                                            name={item.name}
                                            // value={item.name}
                                            {...field}
                                            onChange={() => this.handleChange(item.id, field.value)}
                                          />
                                        }
                                        label={item.name}
                                      />
                                    </FormGroup>
                                    {this.state.typeOfProblems.filter(el => el.isChecked === true).length < 1 && (
                                      <FormHelperText style={{ textAlign: "center" }}>Choose a problem</FormHelperText>
                                    )}
                                  </FormControl>
                                </div>
                              );
                            }}
                          />
                        </div>
                      ))}
                    </div> */}
                    <p className="dialog-askSmileAssessment-simplePaper-text">{_t("Upload photo:")}</p>
                    <p className="dialog-askSmileAssessment-simplePaper-text dialog-askSmileAssessment-simplePaper-textSmall">
                      {_t("Take a photo of your smile as recommended")}
                      {this.state.staticImages.map(el => (
                        el.imgUpload === null && (

                          <div className="pass-error">{this.state.errorImage}</div>
                        )))}
                    </p>
                    <div className="dialog-askSmileAssessment-simplePaper-uploading">
                      {this.state.staticImages.map(item => (
                        <div className="ProtocolPhotos__item" key={item.staticImageId}>
                          <div className="item-content">
                            <label className="item-content-label" onClick={e => this.staticImageAction(e, item)}>
                              <div className="item-content-action">
                                {!item.imgUpload ? (
                                  <span className="addImage">+</span>
                                ) : (
                                  <span className="removeImage">{_t("Delete")}</span>
                                )}
                              </div>
                              {item.imgUpload ? (
                                <ImageCropper src={item.imgUpload} className="item-content-image" />
                              ) : (
                                <img className="item-content-image" src={item.imgPreview} alt="ProtocolPhotos" />
                              )}
                              <input
                                type="file"
                                name={`image_${item.staticImageId}`}
                                accept="image/*"
                                onChange={event => {
                                  setFieldValue(`image_${item.staticImageId}`, event.currentTarget.files[0]);
                                  this.staticImageAttach(event, item.staticImageId);
                                }}
                              />
                            </label>
                          </div>
                          <p className="item-title">{item.title}</p>
                        </div>
                      ))}
                    </div>
                    <p className="dialog-askSmileAssessment-simplePaper-text dialog-askSmileAssessment-simplePaper-textSmall">
                      {_t("After sending the data, you will receive a response within one two days")}
                    </p>
                    {/* <ReCaptcha
                    sitekey="6Ld4ZcUUAAAAAG6kcDGzAQYdyIglfdqas6S755oP"
                    action="smile_assessment_dialog"
                    verifyCallback={this.verifyCallback}
                  /> */}
                    <ReCAPTCHA
                      className="text-center"
                      sitekey="6LcAi-sUAAAAABxrvqvBS5kk2ay8gR2bSbh1ryUl"
                      onChange={this.onChange}
                      name="recaptcha"
                    />
                    <Button
                      disabled={this.state.disabl}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="btn askSmileAssessment-btn"
                    >
                      {_t("Send")}
                    </Button>
                  </div>
                </Form>
              )}
            />
          ) : (
            <Paper className="dialog-doctorRegister-confirm">
              <div className="checkEmail">
                <p className="checkEmail-title">{_t("Thank you for contacting our company!")}</p>
                <p className="checkSmile-text">
                  {_t(
                    "You will receive an assessment of your smile within few days to the Email that you indicated in the application",
                  )}
                </p>
                <p className="checkEmail-email">{this.state.email}</p>
                <p className="checkEmail-text">
                  {_t(
                    "If you have additional questions, you can contact us by e-mail {{address}} or by phone number {{phone}}",
                    { address: "veneeraligner@gmail.com", phone: "+99 1234 45 56 678" },
                  )}
                </p>
              </div>
            </Paper>
          )}
        </div>
      </Dialog>
    );
  }
}

DialogAssessment.propTypes = {
  t: propTypes.func.isRequired,
};

const mapStateToProps = ({ profile, fileUpload }) => ({
  profile,
  fileUploadList: fileUpload.fileUploadList,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DialogAssessment));
