import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import SignInForm from "../../../../components/SignInForm";
import { Dialog, Toolbar, AppBar, IconButton, DialogTitle, Paper } from "@material-ui/core";
import "./index.scss";

class DoctorLogIn extends PureComponent {
  render() {
    const { t: _t } = this.props;
    return (
      <Dialog
        fullScreen
        open={this.props.isDoctorLogInOpen}
        onClose={this.props.openDoctorLogInDialog}
        aria-labelledby="dialog-doctorRegister"
        className="dialog-doctorRegister"
      >
        <AppBar className="dialog-doctorRegister-appBar">
          <Toolbar className="dialog-doctorRegister-toolBar">
            <IconButton
              color="inherit"
              style={{ fontSize: "14px" }}
              onClick={this.props.openDoctorLogInDialog}
              aria-label="close"
            >
              {_t("Close")}
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogTitle className="dialog-doctorRegister-title">{_t("Log in")}</DialogTitle>
        <Paper className="dialog-doctorRegister-paper">
          <SignInForm />
        </Paper>
      </Dialog>
    );
  }
}

DoctorLogIn.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(DoctorLogIn);
