import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Button, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import DialogFind from "../DialogFind";
import DialogAssessment from "../DialogAssessment";
import p32 from "../../../assets/images/p32.png";
import p33 from "../../../assets/images/p33.png";
import p34 from "../../../assets/images/p34.png";
import "./index.scss";
import scrollToTop from "../../../modules/scrollToTop";

class ForPatients extends PureComponent {
  state = {
    transform: 0,
    isDialogFindOpen: false,
    isDialogAssessmentOpen: false,
    smileAssessmentStep: false,
  };

  componentDidMount() {
    scrollToTop();
    window.addEventListener("scroll", this.scrollHandler, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHandler);
  }

  scrollHandler = () => {
    this.setState({
      transform: window.scrollY / 4,
    });
  };

  DialogFindHandler = () => {
    this.setState({
      isDialogFindOpen: !this.state.isDialogFindOpen,
    });
  };

  DialogAssessmentHandler = () => {
    this.setState({
      isDialogAssessmentOpen: !this.state.isDialogAssessmentOpen,
      smileAssessmentStep: false,
    });
  };

  smileChangePage = () => {
    this.setState({
      smileAssessmentStep: true,
    });
  };

  render() {
    const { t: _t } = this.props;
    const { transform, isDialogFindOpen, isDialogAssessmentOpen, smileAssessmentStep } = this.state;
    return (
      <div className="page3">
        <DialogFind isDialogFindOpen={isDialogFindOpen} closeDialogFind={this.DialogFindHandler} />
        <DialogAssessment smileChangePage={this.smileChangePage} smileAssessmentStep={smileAssessmentStep} isShow={isDialogAssessmentOpen} isClose={this.DialogAssessmentHandler} />
        <div className="section1" style={{ transform: `translate3d(0, ${transform}px, 0)` }}>
          <div className="container-inner">
            <div className="section1-title">
              <h1>{_t("Get your dream smile")}</h1>
              <ul>
                <li>{_t("Choose the design of your veneers that fit your face and smile.")}</li>
                <li>{_t("What you’ll choose virtually you’ll get physically inside your mouth.")}</li>
                <li>{_t("Check your veneers in a provisional try in before you confirm your final veneers.")}</li>
                <li>{_t("Option to choose from 12 Harmony designs and 30 Artistic designs.")}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="section2">
          <div className="container">
            <div className="simplePaper">
              <div className="container-inner">
                <div className="smileSection">
                  <Button onClick={this.DialogAssessmentHandler} className="btn">{_t("Smile assessment")}</Button>
                  {_t(" Get an online consultation from a doctor")}
                </div>
                <div className="first">
                  <div className="first__wrap">
                    <h2>
                      {_t("High Quality material with ultra strength and ultra thin bio compatible ceramic material")}
                    </h2>
                    <p>
                      {_t(
                        "New innovative technology to fabricate ultra strength and ultra thin veneers to be compatible with your teeth and rejuvenate your smile.",
                      )}
                    </p>
                  </div>
                  <img src={p32} alt="" />
                </div>
              </div>
              <div className="second">
                <div className="vbtn" onClick={this.DialogFindHandler}>
                  <p>{_t("FIND SELFIEVENEER DENTIST")} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ForPatients.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(ForPatients);
