import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  FormControlLabel,
  Paper,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { API } from "../../modules/Api";
import Use from "../../layouts/LoginLayout/Footer/Dialogs/Use";
import Logger from "../../modules/Logger";
import "./index.scss";

class SignUpForm extends Component {
  state = {
    isOpenTermsOfUse: false,
    termsCheckbox: true,
    errorEmail: "",
    errorPassword: "",
    isPassMatches: "",
  };

  SignUpSchema = () => {
    const { t: _t } = this.props;
    return Yup.object().shape({
      name: Yup.string().required(_t("Required")),
      clinic: Yup.string().required(_t("Required")),
      phone_number: Yup.string().required(_t("Required")),
      country: Yup.string().required(_t("Required")),
      shipping_address: Yup.string().required(_t("Required")),
      email: Yup.string()
        .email(_t("Invalid email"))
        .required(_t("Required")),
      password: Yup.string().required(_t("Required")),
      password2: Yup.string().required(_t("Required")),
    });
  };

  signUp = form => {
    if (!this.state.termsCheckbox) {
      return false;
    }
    if (form.password !== form.password2) {
      this.setState({
        isPassMatches: "Passwords do not match",
      });
      return false;
    }
    this.setState({
      isPassMatches: null,
    });
    // console.warn("Данные формы----------------------", form);
    let registerData = {
      email: form.email,
      password: form.password,
      name: form.name,
      clinic: form.clinic,
      phone_number: form.phone_number,
      shipping_address: form.shipping_address,
      country: form.country,
    };
    API.post("/user/register", registerData).then(
      response => {
        Logger.info(response);
        this.props.registerChangePage(form.email);
      },
      error => {
        const errors = error.map(el => el.error.field);
        if (errors.includes("email") && errors.includes("password")) {
          this.setState({
            errorEmail: "Email already used",
            errorPassword:
              "Password must contain at least 6 characters. Special characters must not be used. Letters and numbers should be used",
          });
        } else if (errors.includes("password")) {
          this.setState({
            errorEmail: "",
            errorPassword:
              "Password must contain at least 6 characters. Special characters must not be used. Letters and numbers should be used",
          });
        } else {
          this.setState({
            errorEmail: "Email already used",
            errorPassword: "",
          });
        }
        console.log("errorRegister", error.map(el => el.error.message));
      },
    );
  };
  termsOfUseDialogHandler = () => {
    this.setState({
      isOpenTermsOfUse: !this.state.isOpenTermsOfUse,
    });
  };
  termsCheckboxHandler = () => {
    this.setState({
      termsCheckbox: !this.state.termsCheckbox,
    });
  };

  render() {
    const { t: _t } = this.props;
    const { isOpenTermsOfUse, termsCheckbox, isPassMatches, errorEmail, errorPassword } = this.state;
    return (
      <div>
        <div className="signUpForm">
          <Formik
            initialValues={{
              name: "",
              clinic: "",
              phone_number: "",
              country: "",
              shipping_address: "",
              email: "",
              password: "",
              // password2: "",
            }}
            validationSchema={this.SignUpSchema}
            onSubmit={this.signUp}
            render={({ errors }) => (
              <Form>
                <Field
                  name="name"
                  render={({ field }) => (
                    <TextField
                      type="string"
                      label="Doctor's Name"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      error={!!errors.name}
                      helperText={errors.name}
                      {...field}
                    />
                  )}
                />
                <Field
                  name="clinic"
                  render={({ field }) => (
                    <TextField
                      type="string"
                      label="Clinic"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      error={!!errors.clinic}
                      helperText={errors.clinic}
                      {...field}
                    />
                  )}
                />
                <Field
                  name="phone_number"
                  render={({ field }) => (
                    <TextField
                      type="string"
                      label="Tel/Mob"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      error={!!errors.phone_number}
                      helperText={errors.phone_number}
                      {...field}
                    />
                  )}
                />
                <Field
                  name="country"
                  render={({ field }) => (
                    <TextField
                      type="string"
                      label="Country"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      error={!!errors.country}
                      helperText={errors.country}
                      {...field}
                    />
                  )}
                />
                <Field
                  name="shipping_address"
                  render={({ field }) => (
                    <TextField
                      type="string"
                      label="Shipping address"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      error={!!errors.shipping_address}
                      helperText={errors.shipping_address}
                      {...field}
                    />
                  )}
                />
                <Field
                  name="email"
                  render={({ field }) => (
                    <TextField
                      type="email"
                      label="Email Address"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      error={!!errors.email}
                      helperText={errors.email}
                      {...field}
                    />
                  )}
                />
                <div className="pass-error">{errorEmail}</div>
                <Field
                  name="password"
                  render={({ field }) => (
                    <TextField
                      type="password"
                      label="Password"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      error={!!errors.password}
                      helperText={errors.password}
                      {...field}
                    />
                  )}
                />
                <div className="pass-error">{errorPassword}</div>
                <Field
                  name="password2"
                  render={({ field }) => (
                    <TextField
                      type="password"
                      label="Password again"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      error={!!errors.password2}
                      helperText={errors.password2}
                      {...field}
                    />
                  )}
                />
                <div className="pass-error">{isPassMatches}</div>
                <div className="signUp-terms">
                  <FormControlLabel
                    control={<Checkbox checked={termsCheckbox} onChange={this.termsCheckboxHandler} color="primary" />}
                    label={_t("I agree to the terms")}
                  />
                  <Button className="signUp-terms-btn" onClick={this.termsOfUseDialogHandler}>
                    {_t("Terms of Use")}
                  </Button>
                </div>
                <Button type="submit" variant="contained" className={`signUp-btn ${!termsCheckbox ? "disabled" : ""}`}>
                  {_t("Register")}
                </Button>
              </Form>
            )}
          />
          <Dialog
            fullWidth
            maxWidth={"md"}
            open={isOpenTermsOfUse}
            onClose={this.termsOfUseDialogHandler}
            aria-labelledby="dialog-termsOfUse"
          >
            <DialogTitle id="dialog-termsOfUse">{_t("Terms of Use")}</DialogTitle>
            <DialogContent className="dialog-text"><Use /></DialogContent>
            <DialogActions>
              <Button onClick={this.termsOfUseDialogHandler} color="primary">
                {_t("Close")}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

SignUpForm.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(SignUpForm);
