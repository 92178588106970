import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { API } from "../../../modules/Api";
import Logger from "../../../modules/Logger";
import ConfirmPassword from "../../../components/ConfirmPassword";
import { Dialog, Toolbar, AppBar, IconButton, Paper, TextField } from "@material-ui/core";
import "./index.scss";

class ForgotPassword extends PureComponent {
  state = {
    recoveryStep1: false,
    emailPasswordRevovery: "",
  };
  ResetPasswordSchema = () => {
    const { t: _t } = this.props;
    return Yup.object().shape({
      email: Yup.string()
        .required(_t("Mandatory input"))
        .email(_t("Invalid e-mail!")),
    });
  };

  resetPassword = ({ email }) => {
    API.post("user/reset_password", { email }).then(
      response => {
        Logger.info(response);
        this.setState({
          recoveryStep1: !this.state.recoveryStep1,
          emailPasswordRevovery: email,
        });
        // this.props.onClose();
      },
      error => {
        Logger.info(error);
      },
    );
  };
  render() {
    const { t: _t, isShow, onClose } = this.props;
    const { recoveryStep1, emailPasswordRevovery } = this.state;
    return (
      <Dialog
        fullScreen
        open={isShow}
        onClose={onClose}
        aria-labelledby="dialog-ForgotPassword"
        className="dialog-ForgotPassword"
      >
        <AppBar className="dialog-ForgotPassword-appBar">
          <Toolbar className="dialog-ForgotPassword-toolBar">
            <IconButton color="inherit" onClick={onClose} aria-label="close">
              {_t("Close")}
            </IconButton>
          </Toolbar>
        </AppBar>
        <p className="dialog-ForgotPassword-title">{_t("Recovery password")}</p>
        <Paper className="dialog-ForgotPassword-paper">
          {recoveryStep1 === false ? (
            <div>
              <p className="dialog-ForgotPassword-paper-title">
                {_t(
                  "Indicate the email address which was used for your account registration and the system will send you a new password to this email address",
                )}
              </p>
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={this.ResetPasswordSchema()}
                onSubmit={this.resetPassword}
                render={({ errors }) => (
                  <Form id="ForgotPassword-form">
                    <Field
                      name="email"
                      render={({ field }) => (
                        <TextField
                          label={_t("Email")}
                          type="string"
                          margin="dense"
                          variant="outlined"
                          className="ForgotPassword-form-input"
                          error={!!errors.email}
                          helperText={errors.email}
                          {...field}
                        />
                      )}
                    />
                    <button type="submit" className="invite-btn">
                      {_t("SEND")}
                    </button>
                  </Form>
                )}
              />
            </div>
          ) : (
            <ConfirmPassword email={emailPasswordRevovery} />
          )}
        </Paper>
      </Dialog>
    );
  }
}

ForgotPassword.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(ForgotPassword);
