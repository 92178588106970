import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import DialogRegister from "../DialogRegister";
import concept1 from "../../../assets/videos/concept1.mp4";
import concept2 from "../../../assets/videos/concept2.mp4";
import p41 from "../../../assets/images/p41.png";
import p42 from "../../../assets/images/p42.png";
import p43 from "../../../assets/images/p43.png";
import p44 from "../../../assets/images/p44.png";
import "./index.scss";
import scrollToTop from "../../../modules/scrollToTop";

class Concept extends PureComponent {
  state = {
    transform: 0,
    isDialogRegisterOpen: false,
  };

  componentDidMount() {
    scrollToTop();
    window.addEventListener("scroll", this.scrollHandler, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHandler);
  }

  scrollHandler = () => {
    this.setState({
      transform: window.scrollY / 4,
    });
  };

  DialogRegisterHandler = () => {
    this.setState({
      isDialogRegisterOpen: !this.state.isDialogRegisterOpen,
    });
  };

  render() {
    const { t: _t } = this.props;
    const { transform, isDialogRegisterOpen } = this.state;
    return (
      <div className="page4">
        <DialogRegister isDialogRegisterOpen={isDialogRegisterOpen} closeDialogRegister={this.DialogRegisterHandler} />
        <div className="section1" style={{ transform: `translate3d(0, ${transform}px, 0)` }}>
          <video src={concept1} autoPlay muted loop />
        </div>
        <div className="section2">
          <div className="container">
            <div className="simplePaper">
              <h2>{_t("Selfieveneer Concept")}</h2>
              <div className="concept__item">
                <div className="concept__container">
                  <div className="concept__text">
                    <h3>{_t("Digital Smile design")}</h3>
                    <p>{_t("Two Libraries:")}</p>
                    <p>{_t("Artistic and Harmony with a total of 42 different Designs to build a DSD")}</p>
                  </div>
                  <img src={p41} alt="" />
                </div>
              </div>
              <div className="concept__item transparent">
                <div className="concept__container reverse">
                  <div className="concept__text">
                    <h3>{_t("Try In veneers")}</h3>
                    <p>{_t("We are innovative to provide a Physical Try In veneer where the patient can try his")}</p>
                    <p>
                      {_t(
                        "veneers and confirm the final result without the need for the traditional way of doing mock up.",
                      )}
                    </p>
                  </div>
                  <img src={p42} alt="" />
                </div>
              </div>
              <div className="concept__item">
                <div className="concept__container">
                  <div className="concept__text">
                    <h3>{_t("Fixing Selfieveneer")}</h3>
                    <p>{_t("After confirmation the design of the veneer we provide")}</p>
                    <p>{_t("the final Ultra strength Ultra thin veneers to be fixed by the dentist.")}</p>
                  </div>
                  <img src={p43} alt="" />
                </div>
              </div>
              <div className="concept__item transparent">
                <div className="concept__container p0">
                  <div className="concept__text">
                    <h3>{_t("Communication")}</h3>
                    <p>{_t("Communication tool via our innovative platform to assist")}</p>
                    <p>{_t("and analyze the smile, choose and confirm the final design.")}</p>
                  </div>
                  <img src={p44} alt="" />
                </div>
              </div>
              <div className="concept__video">
                <video src={concept2} autoPlay muted loop />
              </div>
              <h2>{_t("Register Now to be a Selfieveneer supplier and start creating beautiful smiles")}</h2>
              <div className="vbtn" onClick={this.DialogRegisterHandler}>
                <p>{_t("Register")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Concept.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(Concept);
