import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
// import { API } from "../../../modules/Api";
import { finishRegister } from "../../../actions";
// import Logger from "../modules/Logger";
// import { push } from "connected-react-router";
import propTypes from "prop-types";
import logoArab from "../../../assets/img/logo.png";
import DoctorLogIn from "../Header/DialogLogIn";
import "./index.scss";

class ConfirmPage extends PureComponent {
  state = {
    isDoctorLogInOpen: false,
  };
  componentDidMount() {
    this.signUpConfirm();
  }

  signUpConfirm = () => {
    this.props.finishRegister(this.props.match.params.token);
  };

  openDoctorLogInDialog = () => {
    this.setState({ isDoctorLogInOpen: !this.state.isDoctorLogInOpen });
  };

  render() {
    const { isDoctorLogInOpen } = this.state;
    return (
      <div className="confirm">
        <table cellPadding="0" cellSpacing="0" border="0" id="mainContent">
          <tr>
            <td className="container">
              <div className="content">
                <div className="logoBox">
                  <img src={logoArab} className="logoBox-img img-responsive" inline alt="" />
                </div>
                <table role="presentation" className="main">
                  <tbody>
                    <tr>
                      <td className="wrapper">
                        <table role="presentation" border="0" cellPadding="0" cellSpacing="0">
                          <tbody>
                            <tr>
                              <td>
                                <p className="fw700">
                                  Your email has already been confirmed, now you will be redirected, if this did not
                                  happen, click on the button yourself
                                </p>
                                <div className="btn-container">
                                  <div onClick={this.openDoctorLogInDialog} className="btn btn-primary">
                                    Log in
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </table>
        <DoctorLogIn isDoctorLogInOpen={isDoctorLogInOpen} openDoctorLogInDialog={this.openDoctorLogInDialog} />
      </div>
    );
  }
}

ConfirmPage.propTypes = {
  t: propTypes.func.isRequired,
};

const mapStateToProps = ({ login, profile }) => ({
  // status: profile.account_type,
  // email: login.email,
});

const mapDispatchToProps = {
  finishRegister,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ConfirmPage));
