import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { login } from "../../actions";
import propTypes from "prop-types";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { TextField, Button } from "@material-ui/core";
import ForgotPassword from "../../layouts/LoginLayout/ForgotPassword";
import { withRouter, Redirect } from "react-router";
import "./index.scss";

class SignInForm extends Component {
  state = {
    isForgotPasswordDialog: false,
  };
  SignInSchema = () => {
    const { t: _t } = this.props;
    return Yup.object().shape({
      email: Yup.string().required(_t("Mandatory input")),
      password: Yup.string().required(_t("Mandatory input")),
    });
  };

  login = ({ email, password }) => {
    this.props.login(email, password);
  };

  toggleForgotPasswordDialog = () => {
    this.setState({ isForgotPasswordDialog: !this.state.isForgotPasswordDialog });
  };

  render() {
    const { t: _t, networkErrors } = this.props;
    if (this.props.isAuth) {
      return <Redirect to="/profile" />;
    }
    return (
      <div className="signInForm">
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={this.SignInSchema()}
          onSubmit={values => {
            this.login(values);
          }}
        >
          {({ errors }) => (
            <Form>
              <Field name="email">
                {({ field }) => (
                  <TextField
                    type="string"
                    label="Login"
                    margin="dense"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email}
                    {...field}
                  />
                )}
              </Field>
              <Field name="password">
                {({ field }) => (
                  <TextField
                    type="password"
                    label="Password"
                    margin="dense"
                    fullWidth
                    error={!!errors.password}
                    helperText={errors.password}
                    {...field}
                  />
                )}
              </Field>
              <div style={{ maxWidth: 300 }}>
                {networkErrors && networkErrors.length > 0 && <div>{_t("Wrong log in or password!")}</div>}
              </div>
              <Button type="submit" variant="contained" color="primary" className="btn signInForm-btn">
                {_t("Log in")}
              </Button>
              <p className="signInForm-btn-forgot" onClick={this.toggleForgotPasswordDialog}>
                Forgot password?
              </p>
            </Form>
          )}
        </Formik>
        <ForgotPassword isShow={this.state.isForgotPasswordDialog} onClose={this.toggleForgotPasswordDialog} />
      </div>
    );
  }
}

SignInForm.propTypes = {
  t: propTypes.func.isRequired,
};

const mapStateToProps = ({ login }) => ({
  networkErrors: login.errors,
  isAuth: login.isAuth,
});

const mapDispatchToProps = {
  login,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SignInForm)));
