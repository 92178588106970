import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Route, Switch } from "react-router";
// import { Helmet } from "react-helmet";
// import SignInForm from "../../components/SignInForm";
// import SignUpForm from "../../components/SignUpForm";
import HomePage from "./HomePage";
import ForDentists from "./ForDentists";
import ForPatients from "./ForPatients";
// import HowWork from "./HowWork";
import Concept from "./Concept";
import ContactUs from "./ContactUs";
// import aDoctor from "./aDoctor";
import Header from "./Header";
import Footer from "./Footer";
import ConfirmPage from "./ConfirmPage";
import ChangeEmailSuccess from "./ChangeEmailSuccess";
import DoctorLogIn from "./Header/DialogLogIn";

import "./index.scss";
// import scrollToTop from "../../modules/scrollToTop";

class LoginLayout extends PureComponent {
  state = {
    isDoctorLogInOpen: false,
  };

  openDoctorLogInDialog = () => {
    this.setState({ isDoctorLogInOpen: !this.state.isDoctorLogInOpen });
  };

  render() {
    // const { t: _t } = this.props;
    const { isDoctorLogInOpen } = this.state;
    return (
      <div className="loginLayout">
        <Header openDoctorLogInDialog={this.openDoctorLogInDialog} />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/sign_up/:token" component={ConfirmPage} />
          <Route exact path="/change_email/:token" component={ChangeEmailSuccess} />
          <Route exact path="/for-dentists" component={ForDentists} />
          <Route exact path="/for-patients" component={ForPatients} />
          <Route exact path="/concept" component={Concept} />
          <Route exact path="/contact-us" component={ContactUs} />
        </Switch>
        <Footer />
        <DoctorLogIn isDoctorLogInOpen={isDoctorLogInOpen} openDoctorLogInDialog={this.openDoctorLogInDialog} />
      </div>
    );
  }
}

LoginLayout.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(LoginLayout);
