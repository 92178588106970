import React from "react";
import propTypes from "prop-types";
import { LinearProgress } from "@material-ui/core";

import "./index.sass";

const FileLoader = ({ fileLoadingList }) => {
  if (!fileLoadingList.length) {
    return null;
  }
  return (
    <div id="fileLoader">
      {fileLoadingList.map(file => (
        <div className="fileLoader-item" key={file.fileName}>
          <LinearProgress variant="determinate" value={file.progress} />
          <p className="fileLoader-item-name">{file.fileName}</p>
        </div>
      ))}
    </div>
  );
};

FileLoader.propTypes = {
  fileLoadingList: propTypes.array.isRequired,
};

export default FileLoader;
