import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import DialogProtocol from "../DialogProtocol";
import DialogRegister from "../DialogRegister";
import p2s11 from "../../../assets/images/p2s11.png";
import p2s12 from "../../../assets/images/p2s12.png";
import p2s13 from "../../../assets/images/p2s13.png";
import p2s22 from "../../../assets/images/p2s22.png";
import p2s31 from "../../../assets/images/p2s31.png";
import p2s32 from "../../../assets/images/p2s32.png";
import p2s33 from "../../../assets/images/p2s33.png";
import p2s41 from "../../../assets/images/p2s41.png";
import "./index.scss";
import scrollToTop from "../../../modules/scrollToTop";

class ForDentists extends PureComponent {
  state = {
    transform: 0,
    isDialogProtocolOpen: false,
    isDialogRegisterOpen: false,
  };

  componentDidMount() {
    scrollToTop();
    window.addEventListener("scroll", this.scrollHandler, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHandler);
  }

  scrollHandler = () => {
    this.setState({
      transform: window.scrollY / 4,
    });
  };

  DialogProtocolHandler = () => {
    this.setState({
      isDialogProtocolOpen: !this.state.isDialogProtocolOpen,
    });
  };

  DialogRegisterHandler = () => {
    this.setState({
      isDialogRegisterOpen: !this.state.isDialogRegisterOpen,
    });
  };

  render() {
    const { t: _t } = this.props;
    const { transform, isDialogProtocolOpen, isDialogRegisterOpen } = this.state;
    return (
      <div className="page2">
        <DialogRegister isDialogRegisterOpen={isDialogRegisterOpen} closeDialogRegister={this.DialogRegisterHandler} />
        <DialogProtocol isDialogProtocolOpen={isDialogProtocolOpen} closeDialogProtocol={this.DialogProtocolHandler} />
        <div className="section1" style={{ transform: `translate3d(0, ${transform}px, 0)` }}>
          <div className="container1">
            <div className="section1-title">
              <h1>{_t("Benefits of Selfieveneer suppliers")}</h1>
              <ul>
                <li>
                  {_t(
                    "Possibility of choosing a 2D smile design from 42 libraries and transferring it to 3D printable veneers.",
                  )}
                </li>
                <li>
                  {_t(
                    "Possibility of trying the veneers inside the patient’s mouth and seeing the actual result with Try in veneers printed from the same 2D template.",
                  )}
                </li>
                <li>
                  {_t(
                    "Possibility of printing veneers of 0.1mm thickness from ultra hard and ultra strength bio compatible ceramic material.",
                  )}
                </li>
                <li>
                  {_t(
                    "Availability of two libraries (artistic and harmony) with a total of 42 different designs of veneers to be chosen according to the contour of the face and age of the patient.",
                  )}
                </li>
                <li>
                  {_t(
                    "Communication between doctors, patients and our company is very simple and efficient through our innovative platform.",
                  )}
                </li>
              </ul>
              {/* <div className="vbtn" onClick={this.DialogRegisterHandler}>
                <p>{_t("Register")}</p>
              </div> */}
            </div>
          </div>
        </div>
        <div className="section2">
          <div className="container">
            <div className="simplePaper">
              <div className="first">
                {[
                  { id: 1, pic: p2s11, text1: _t("DSD"), text2: _t("Template") },
                  { id: 2, pic: p2s12, text1: _t("3D"), text2: _t("Template") },
                  { id: 3, pic: p2s13, text1: _t("3D"), text2: _t("Physical Veneers") },
                ].map(el => (
                  <div className="first__item" key={el.id}>
                    <img src={el.pic} alt="" />
                    <p>{el.text1}</p>
                    <p>{el.text2}</p>
                  </div>
                ))}
              </div>
              <div className="second">
                <div className="container-inner">
                  <h3>{_t("Procedure of submitting case")}</h3>
                  <ul className="numberMarkers">
                    <li>
                      {_t("Dentist sends pictures of the smile and face of the patient (refer to our photo protocol).")}
                    </li>
                    <li>
                      {_t(
                        "Selfieveneer supply digital smile design according to the instructions and preference of the dentist.",
                      )}
                    </li>
                    <li>
                      {_t("Dentist discusses the design of the smile with the patient and confirms the final result.")}
                    </li>
                    <li>{_t("Selfieveneer print a try In Veneer and send them back to the dentist.")}</li>
                    <li>{_t("Patient tries his veneers and confirms the final shape of his veneers.")}</li>
                    <li>{_t("Selfieveneer sends the final veneers.")}</li>
                  </ul>
                  <div className="second__wrap">
                    <div>
                      <h3>{_t("Communication system")}</h3>
                      <p>{_t("We have developed a unique communication system that allows you to:")}</p>
                      <ul>
                        <li>{_t("Place orders of treatment and send pictures of your patients.")}</li>
                        <li>{_t("Receive digital smile design.")}</li>
                        <li>{_t("Save information and records of all patients for a future consultation.")}</li>
                        <li>{_t("Demonstrate to your patients in 2D and 3D their smile design.")}</li>
                        <li>{_t("Send links by emails to your patients to show them their smile design.")}</li>
                      </ul>
                    </div>
                    <img src={p2s22} alt="" />
                  </div>
                </div>
              </div>
              <div className="third">
                <img src={p2s41} alt="" />
                <div className="third__wrap">
                  <h2>{_t("Register now to be a Selfieveneer supplier.")}</h2>
                  <div className="vbtn" onClick={this.DialogRegisterHandler}>
                    <p>{_t("Register")}</p>
                  </div>
                </div>
              </div>
              <div className="fourth">
                <div className="container-inner">
                  <p className="second__text">
                    {_t(
                      "To obtain a predictable result, A DSD must be built based on high quality photos. We developed a protocol of taking photos of the patient’s smile and teeth, please refer to our photo protocol down.",
                    )}
                  </p>
                  <div className="second__protocol">
                    <div className="vbtn" onClick={this.DialogProtocolHandler}>
                      <p>{_t("PHOTO PROTOCOL")}</p>
                    </div>
                    <img src={p2s31} alt="" />
                    <img src={p2s32} alt="" />
                    <img src={p2s33} alt="" />
                  </div>

                  <p className="second__text">
                    {_t(
                      "We use 2 types of libraries for Digital Smile Design and 3D modeling with a total of 42 smile designs to choose from.",
                    )}
                  </p>
                  <div className="fourth__wrap">
                    <p className="second__text">{_t("Artistic library (30 styles)")}</p>
                    <p>{_t("Created from 30 different teeth shapes that match all face types.")}</p>
                    <p className="second__text">{_t("Harmonious library (12 styles)")}</p>
                    <p>
                      {_t(
                        "Designed based on 4 face types with 3 degrees of abrasion for each type depends on the age of the patient.",
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ForDentists.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(ForDentists);
