import React, { PureComponent } from "react";

class ImageCropper extends PureComponent {
  canvasRenderer = React.createRef();

  componentDidMount() {
    this.createPreview();
  }

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      this.createPreview();
    }
  }

  createPreview = () => {
    const ctx = this.canvasRenderer.getContext("2d");
    const image = new Image();
    image.onload = () => {
      const naturalWidth = image.naturalWidth;
      const naturalHeight = image.naturalHeight;

      const targetWidth = 400;
      const targetHeight = 400;

      const cropRatioX = targetWidth / naturalWidth;
      const cropRatioY = targetHeight / naturalHeight;
      const cropRate = Math.min(cropRatioX, cropRatioY);

      const croppedWidth = naturalWidth * cropRate;
      const croppedHeight = naturalHeight * cropRate;

      this.canvasRenderer.width = croppedWidth;
      this.canvasRenderer.height = croppedHeight;

      ctx.drawImage(image, 0, 0, croppedWidth, croppedHeight);
    };
    image.src = this.props.src;
  };

  render() {
    return <canvas ref={node => (this.canvasRenderer = node)} className={this.props.className} />;
  }
}

export default ImageCropper;
