import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Dialog, Toolbar, AppBar, IconButton } from "@material-ui/core";
import protocol1 from "../../../assets/images/protocol1.png";
import protocol2 from "../../../assets/images/protocol2.png";
import protocol3 from "../../../assets/images/protocol3.png";
import protocol4 from "../../../assets/images/protocol4.png";
import protocol5 from "../../../assets/images/protocol5.png";
import protocol6 from "../../../assets/images/protocol6.png";
import "./index.scss";

class DialogProtocol extends PureComponent {
  render() {
    const { t: _t } = this.props;
    return (
      <Dialog
        fullScreen
        open={this.props.isDialogProtocolOpen}
        onClose={this.props.closeDialogProtocol}
        aria-labelledby="dialog-protocol"
        className="dialog-protocol"
      >
        <AppBar className="dialog-doctorRegister-appBar">
          <Toolbar className="dialog-doctorRegister-toolBar">
            <IconButton
              color="inherit"
              style={{ fontSize: "14px" }}
              onClick={this.props.closeDialogProtocol}
              aria-label="close"
            >
              {_t("Close")}
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="dialog-protocol-content">
          <h2>{_t("Veneer In protocol of taking pictures")}</h2>
          <div className="simplePaper">
            <ul>
              <li>{_t("Fix patient head to the wall by a box and his feet stand on the ground.")}</li>
              <li>{_t("Place a cheek retractor while the upper teeth opened  2 mm from the lower teeth.")}</li>
              <li>{_t("Without cheek  retractor with the same previous position of the teeth.")}</li>
              <li>{_t("The camera should be on the  same level of the head on tripod.")}</li>
            </ul>
            <h3>{_t("CORRECT HEAD POSITION")}</h3>
            <img src={protocol1} alt="" className="protocol1" />
            <p>
              {_t(
                "In order to take a  frontal photo of the patient's face,  the camera should be placed so that the direction of the lens is parallel  to the Frankfurt horizontal plane and the plane of the lens is at right angle  to the frontal  plane of the face.  The distance of the camera from the patient head should respect the parameters of the lens.",
              )}
            </p>
            <div className="protocol__wrap1">
              <img src={protocol2} alt="" />
              <p>
                {_t(
                  "An imaginary point in the outer corner of the eye should coincide with the point in the fold of the auricle and the middle of the nose from the right and left sides. In such a way forward and backward tilts of the head are controlled. To control the  position of the head to the right or to the left during taking the frontal photo it is required to evaluate the position of the imaginary points in the middle between the inner and outer corners of the eyes, between the nose and the upper lip along the midline of the head.",
                )}
              </p>
            </div>
            <h3>{_t("NECESSARY PROJECTS FOR PHOTOS")}</h3>
            <div className="protocol__wrap2">
              {[
                { id: 1, pic: protocol3, text: "Frontally lips are closed." },
                { id: 2, pic: protocol4, text: "Frontally with  a retractor." },
                { id: 3, pic: protocol5, text: "Frontally wide smile." },
                { id: 4, pic: protocol6, text: "Frontally with a retractor. Macro shooting." },
              ].map(el => (
                <div className="protocol__item" key={el.id}>
                  <img src={el.pic} alt="" />
                  <p>{el.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

DialogProtocol.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(DialogProtocol);
