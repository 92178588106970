import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Button } from "@material-ui/core";
import logo from "../../../assets/images/Logo.png";
import DialogLogIn from "./DialogLogIn";

import "./index.scss";

class Header extends PureComponent {
  state = {
    inMenuOpen: false,
  };
  componentDidMount() {
    if (window.innerWidth < 992) {
      this.setState({ isMobile: true });
    }
    this.setState({
      inMenuOpen: false,
    });
  }
  toggleNav = () => {
    this.setState({
      inMenuOpen: !this.state.inMenuOpen,
    });
  };
  render() {
    const { t: _t } = this.props;
    const { inMenuOpen } = this.state;
    return (
      <div className="navWrap">
        <div className="container">
          <nav className="nav">
            <NavLink to="/" className="logo">
              <img src={logo} alt="" />
            </NavLink>
            <ul className={inMenuOpen ? "active" : ""}>
              <li onClick={this.toggleNav}>
                <NavLink to="/for-dentists" activeClassName="active">
                  {_t("For Dentist")}
                </NavLink>
              </li>
              <li onClick={this.toggleNav}>
                <NavLink to="/for-patients" activeClassName="active">
                  {_t("For Patients")}
                </NavLink>
              </li>
              <li onClick={this.toggleNav}>
                <NavLink to="/concept" activeClassName="active">
                  {_t("Selfieveneer Concept")}
                </NavLink>
              </li>
              <li onClick={this.toggleNav}>
                <NavLink to="/contact-us" activeClassName="active">
                  {_t("Contact Us")}
                </NavLink>
              </li>

              <li>
                <Button onClick={this.props.openDoctorLogInDialog}>{_t("Log in")}</Button>
              </li>
              <button className="nav-close" onClick={this.toggleNav}>
                <span></span>
                <span></span>
              </button>
            </ul>
            <button className="nav-open" onClick={this.toggleNav}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </nav>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(Header);
