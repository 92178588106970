import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Dialog, Toolbar, AppBar, IconButton, Button, Paper, DialogTitle } from "@material-ui/core";
import SignUpForm from "../../../components/SignUpForm";
import ConfirmEmail from "../../../components/ConfirmEmail";
import "./index.scss";

class DialogRegister extends PureComponent {
  state = {
    isOpenRegister: false,
    registerStep1: false,
    email: "",
  };
  registerChangePage = email => {
    this.setState({
      registerStep1: !this.state.registerStep1,
      email: email,
    });
  };
  render() {
    const { t: _t } = this.props;
    const { isOpenRegister, registerStep1, email } = this.state;
    return (
      <Dialog
        fullScreen
        open={this.props.isDialogRegisterOpen}
        onClose={this.props.closeDialogRegister}
        aria-labelledby="dialog-doctorRegister"
        className="dialog-doctorRegister"
      >
        <AppBar className="dialog-doctorRegister-appBar">
          <Toolbar className="dialog-doctorRegister-toolBar">
            <IconButton
              color="inherit"
              style={{ fontSize: "14px" }}
              onClick={this.props.closeDialogRegister}
              aria-label="close"
            >
              {_t("Close")}
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogTitle className="dialog-doctorRegister-title">{_t("Doctor registration")}</DialogTitle>
        {/* <div className="dialog-register-wrap"> */}
        {/* <h2>{_t("Doctor registration")}</h2> */}
        {registerStep1 === false ? (
          <Paper className="dialog-doctorRegister-paper">
            <SignUpForm registerChangePage={this.registerChangePage} />
          </Paper>
        ) : (
          <Paper className="dialog-doctorRegister-confirm">
            <ConfirmEmail email={email} />
          </Paper>
        )}
        {/* </div> */}
      </Dialog>
    );
  }
}

DialogRegister.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(DialogRegister);
