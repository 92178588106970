import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import DialogOnline from "../DialogOnline";
import "./index.scss";
import scrollToTop from "../../../modules/scrollToTop";

class ContactUs extends PureComponent {
  state = {
    isDialogOnlineOpen: false,
  };
  componentDidMount() {
    scrollToTop();
  }
  DialogOnlineHandler = () => {
    this.setState({
      isDialogOnlineOpen: !this.state.isDialogOnlineOpen,
    });
  };

  render() {
    const { t: _t } = this.props;
    const { isDialogOnlineOpen } = this.state;
    return (
      <div className="page5">
        <DialogOnline isDialogOnlineOpen={isDialogOnlineOpen} closeDialogOnline={this.DialogOnlineHandler} />
        <div className="section1">
          <div className="container1">
            <div className="section1__wrap">
              <h2>{_t("Contacts")}</h2>
              <div className="section1__item">
                <p>{_t("Adress:")}</p>
                <span></span>
              </div>
              <div className="section1__item">
                <p>{_t("Tel/Mob:")}</p>
                <span></span>
              </div>
              <div className="section1__item">
                <p>{_t("Email:")}</p>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div className="section2">
          <h3>{_t("You can contact us right now")}</h3>
          <div className="vbtn" onClick={this.DialogOnlineHandler}>
            <p>{_t("Online")}</p>
          </div>
        </div>
      </div>
    );
  }
}

ContactUs.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(ContactUs);
