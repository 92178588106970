import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Dialog, Toolbar, AppBar, IconButton } from "@material-ui/core";
import findPic2 from "../../../assets/images/findPic2.png";
import "./index.scss";

class DialogFind extends PureComponent {
  render() {
    const { t: _t } = this.props;
    return (
      <Dialog
        fullScreen
        open={this.props.isDialogFindOpen}
        onClose={this.props.closeDialogFind}
        aria-labelledby="dialog-find"
        className="dialog-find"
      >
        <AppBar className="dialog-doctorRegister-appBar">
          <Toolbar className="dialog-doctorRegister-toolBar">
            <IconButton
              color="inherit"
              style={{ fontSize: "14px" }}
              onClick={this.props.closeDialogFind}
              aria-label="close"
            >
              {_t("Close")}
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="dialog-find-content">
          <h2>{_t("Find  Veneer In Dentist")}</h2>
          <p>
            {_t(
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed eleifend tristique, tortor mauris molestie elit, et lacinia ipsum quam nec dui.",
            )}
          </p>
          <div className="dialog-find-item">
            <img src={findPic2} alt="" />
            <div className="dialog-find-paper">
              <h6>{_t("Ivan Ivanov")}</h6>
              <p>
                {_t(
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Duis vulputate commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed eleifend tristique, tortor mauris molestie elit, et lacinia ipsum quam nec dui. Quisque nec mauris sit amet elit iaculis pretium sit amet quis magna. Aenean velit odio, elementum in tempus ut, vehicula eu diam. Pellentesque rhoncus aliquam mattis. Ut vulputate eros sed felis sodales nec vulputate justo hendrerit. Vivamus varius pretium ligula, a aliquam odio euismod sit amet. Quisque laoreet sem sit amet orci ullamcorper at ultricies metus viverra. Pellentesque arcu mauris, malesuada quis ornare accumsan, blandit sed diam.",
                )}
              </p>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

DialogFind.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(DialogFind);
