import React, { Component } from "react";
import propTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Dialog } from "@material-ui/core";
import "./index.scss";

class ConfirmPassword extends Component {
  render() {
    const { t: _t, isShow, onClose, email } = this.props;
    return (
      <div className="checkEmail">
        {/* <p className="checkEmail-title">{_t("Thank you for registering on our platform!")}</p> */}
        <p className="checkEmail-text bold">{_t("We have sent you an email with a new password")}</p>
        <p className="checkEmail-email">{email}</p>
        <div className="checkEmail-text-block">
          <p className="checkEmail-text">{_t("If you did not receive the letter, just in case, check: ")}</p>
          <li className="checkEmail-text-li">{_t("there are no errors in the email address")}</li>
          <li className="checkEmail-text-li">{_t("did the letter fall into the Spam folder")}</li>
        </div>
        <p className="checkEmail-text">
          {_t(
            "If you have any questions regarding registration or access to your  personal page, write to {{address}} we will understand and we will definitely help.",
            { address: "veneeraligner@gmail.com" },
          )}
        </p>
      </div>
    );
  }
}

ConfirmPassword.propTypes = {
  t: propTypes.func.isRequired,
  isShow: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  email: propTypes.string.isRequired,
};

export default withTranslation()(ConfirmPassword);
