import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import DialogFind from "../DialogFind";
import video_bg from "../../../assets/videos/main_bg.mp4";
import p1logo from "../../../assets/images/p1logo.png";
import s21 from "../../../assets/images/s21.png";
import s22 from "../../../assets/images/s22.png";
import s23 from "../../../assets/images/s23.png";
import s31 from "../../../assets/images/s31.png";
import s32 from "../../../assets/images/s32.png";
import s33 from "../../../assets/images/s33.png";
import s42 from "../../../assets/images/s42.png";
import s43 from "../../../assets/images/s43.png";
import s44 from "../../../assets/images/s44.png";
import "./index.scss";
import scrollToTop from "../../../modules/scrollToTop";

class HomePage extends PureComponent {
  state = {
    transform: 0,
    isDialogFindOpen: false,
  };

  componentDidMount() {
    scrollToTop();
    window.addEventListener("scroll", this.scrollHandler, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHandler);
  }

  scrollHandler = () => {
    this.setState({
      transform: window.scrollY / 4,
    });
  };

  DialogFindHandler = () => {
    this.setState({
      isDialogFindOpen: !this.state.isDialogFindOpen,
    });
  };

  render() {
    const { t: _t } = this.props;
    const { transform, isDialogFindOpen } = this.state;
    return (
      <div className="page1">
        <DialogFind isDialogFindOpen={isDialogFindOpen} closeDialogFind={this.DialogFindHandler} />
        <div className="section1" style={{ transform: `translate3d(0, ${transform}px, 0)` }}>
          <video src={video_bg} autoPlay muted loop />
          <div className="section1-title">
            <img src={p1logo} alt="" />
            <p>{_t("Get your beautiful digital smile you always dreams of")}</p>
          </div>
        </div>
        <div className="section2">
          <div className="container">
            <div className="simplePaper">
              <div className="first">
                {[
                  { id: 1, pic: s21, text: _t("Choose your Design") },
                  { id: 2, pic: s22, text: _t("Try your Veneers") },
                  { id: 3, pic: s23, text: _t("Final Smile") },
                ].map(el => (
                  <div className="first__item" key={el.id}>
                    <img src={el.pic} alt="" />
                    <p>{el.text}</p>
                  </div>
                ))}
              </div>
              <div className="second">
                <div className="second__wrap">
                  {[
                    { id: 1, pic: s31, text: _t("High Dental Technology") },
                    { id: 2, pic: s32, text: _t("Patient friendly") },
                    { id: 3, pic: s33, text: _t("Special platform for communication and ordering") },
                  ].map(el => (
                    <div className="second__item" key={el.id}>
                      <img src={el.pic} alt="" />
                      <p>{el.text}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="third">
                <h2>{_t("Choose your Smile, Try it and Enjoy it.")}</h2>
                {/* <div className="vbtn third__vbtn" onClick={this.DialogFindHandler}>
                  <p>{_t("FIND SELFIEVENEER DENTIST")}</p>
                </div> */}
              </div>
              <div className="fourth">
                {[
                  { id: 1, route: "/concept", pic: s42, text: _t("SELFIEVENEER CONCEPT"), btnClass: "blue" },
                  { id: 2, route: "/for-dentists", pic: s43, text: _t("FOR DENTIST"), btnClass: "pink" },
                  { id: 3, route: "/for-patients", pic: s44, text: _t("FOR PATIENT"), btnClass: "violet" },
                ].map(el => (
                  <Link to={el.route} key={el.id} className="fourth__wrap">
                    <div className="fourth__item">
                      <img src={el.pic} alt="" />
                      <div className={`vbtn fourth__vbtn fourth__vbtn_${el.btnClass}`}>
                        <p>{el.text}</p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HomePage.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(HomePage);
